import React from 'react';
import { Message, Avatar } from '@chatscope/chat-ui-kit-react';
import ChatSwiperCarousel from '../ChatSwiperCarousel';
import { ChatGrid } from '../ChatGrid';
import { ChatSection } from './ChatSection';
import { ChatSectionWithCarousel } from './ChatSectionWithCarousel';
import { ChatCallToAction } from './ChatCallToAction';
import { ChatSectionWithSubSection } from './ChatSectionWithSubSection';
import { ChatRedirectButton } from './ChatRedirectButton';
import RawHTML from '@components/Common/RawHTML';
import { ChatAISeparator } from './ChatAISeparator';
import chatLogo from '../../../../images/Chatbot/ChatbotBeaverLogo.png';

export const ChatResponseGroup = ({ messages, isAIFlow }) => {
  const renderMessageContent = (msg, isProcessing, isTyping) => {
    if (msg.type === 'ai_separator') {
      return <ChatAISeparator />;
    }

    if (msg.type === 'text') {
      // Convert message to string and handle undefined/null
      const messageText = String(msg.message || '');
      // For chatbot responses, we already have HTML content, so prevent newline conversion
      const cleanMessage = messageText.includes('<')
        ? messageText
        : messageText.replace(/\n/g, '<br />');
      return (
        <Message.CustomContent>
          <RawHTML className="chatbot-ai-response">{cleanMessage}</RawHTML>
        </Message.CustomContent>
      );
    }

    return (
      <Message.CustomContent>
        {msg.type === 'carousel' && <ChatSwiperCarousel items={msg.payload} />}
        {msg.type === 'grid' && <ChatGrid items={msg.payload} />}
        {msg.type === 'section' && <ChatSection items={msg.payload} />}
        {msg.type === 'section_with_carousel' && (
          <div>
            <ChatSectionWithCarousel items={msg.payload} />
          </div>
        )}
        {msg.type === 'callToAction' && (
          <ChatCallToAction
            text={msg.payload.text}
            link={msg.payload.link}
            programInterested={msg.payload?.programInterested}
          />
        )}
        {msg.type === 'section_with_sub_section' && (
          <ChatSectionWithSubSection items={msg.payload} />
        )}
        {msg.type === 'redirectButton' && (
          <ChatRedirectButton text={msg.payload.text} link={msg.payload.link} />
        )}
      </Message.CustomContent>
    );
  };

  // Only show avatar for bot messages in AI flow
  const shouldShowAvatar = isAIFlow && messages[0]?.sender === 'bot';

  return (
    <div className="flex max-w-[80%] flex-col gap-1">
      {shouldShowAvatar && (
        <Avatar src={chatLogo} name="Beavie" size="sm" className=" " />
      )}
      <div className="chat-response-group my-1 mb-4 rounded-lg bg-[#7A8B871A] p-4">
        <div className="flex flex-col gap-4">
          {messages.map((msg, index) => (
            <Message
              key={index}
              model={{
                message: msg.message,
                sentTime: msg.sentTime,
                sender: msg.sender,
                direction: msg.direction,
                position: msg.position,
              }}
              className="text-black"
            >
              {renderMessageContent(msg)}
            </Message>
          ))}
        </div>
      </div>
    </div>
  );
};
