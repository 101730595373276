import React from 'react';

export const ChatOptions = ({ items, onSelect, disabled, isAIFlowActive }) => {
  console.log('🚀 ~ ChatOptions ~ isAIFlowActive:', isAIFlowActive);
  return (
    <div className="flex flex-col gap-2">
      {items.map((item, index) => (
        <button
          key={index}
          onClick={() => !disabled && onSelect(item.value)}
          className={`group relative w-full rounded-lg border-[1px] border-solid border-black px-3 py-2.5 text-left text-sm font-normal text-black transition-colors hover:bg-[#F2FBF7] disabled:cursor-not-allowed disabled:opacity-50 md:px-4 md:py-3`}
          disabled={disabled || isAIFlowActive}
        >
          <div className="flex w-full items-center justify-between">
            <span className="text-left">{item.text}</span>
            <svg
              className="ml-3 h-5 w-5 shrink-0 transform text-black transition-transform group-hover:translate-x-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </button>
      ))}
    </div>
  );
};
