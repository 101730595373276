import React from 'react';
import { ChevronDown, RotateCcw } from 'lucide-react';
import { Tooltip } from './Tooltip';

export const ChatControls = ({ onClose, onReset }) => {
  return (
    <div className="flex items-center gap-2 text-white ">
      <button onClick={onReset} aria-label="Restart chat">
        <RotateCcw
          size={18}
          className="transition-all duration-200 hover:scale-125 "
          color="white"
        />
      </button>
      <Tooltip content="Close chat">
        <button
          onClick={onClose}
          className="rounded-full p-2 text-white transition-all duration-200 hover:scale-125 "
          aria-label="Close chat"
        >
          <ChevronDown size={24} />
        </button>
      </Tooltip>
    </div>
  );
};
