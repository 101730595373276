/**
 * Handles AI-related functionality for the chatbot
 */

import { createBotMessage } from './chatUtils.js';
import CryptoJS from 'crypto-js';

const API_KEY = process.env.OPEN_AI_SERVICE_X_API_KEY;
const SECRET_KEY = process.env.EXTERNAL_CHATBOT_SECRET_KEY;

const ASSISTANT_ID = 'asst_9sFQflJgfvlEoT0iRR734gdL';
const BASE_URL =
  'https://9ucjoxqsdi.execute-api.ap-south-1.amazonaws.com/prod/v1/assistant/thread';
const SIGNATURE_URL =
  'https://9ucjoxqsdi.execute-api.ap-south-1.amazonaws.com/prod/external-chatbot-util-service?path=generate-signature';

// Store thread ID in session storage
const THREAD_KEY = 'chatbot_thread_id';

/**
 * The AI responses can contain various types of line breaks and citation markers.
 * This function normalizes content to have single line breaks between elements.
 *
 * @param {string} message - The message to sanitize
 * @returns {string} Sanitized message with normalized line breaks
 */
const sanitizeMessage = (message) => {
  return (
    message
      // First remove citations
      .replace(/【\d+:\d+†source】/g, '')
      // Convert br tags to newlines
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<br>/gi, '\n')
      // Add line breaks between elements
      .replace(/(<\/p>)(<[pb]|ul)/gi, '$1\n$2') // Add newline after </p>
      .replace(/(<\/ul>)(<[pb]|ul)/gi, '$1\n$2') // Add newline after </ul>
      .replace(/(<\/b>)(<[pb]|ul)/gi, '$1\n$2') // Add newline after </b>
      .replace(/(\d+\.\s*)(<[pb]|ul)/g, '$1\n$2') // Add newline after numbered sections
      // Ensure line breaks around list items and sections
      .replace(/(<\/li>)(<li|<\/ul|<b)/g, '$1\n$2') // Add newline after list items
      .replace(/(<b>\d+\.)/g, '\n$1') // Add newline before numbered sections
      .replace(/(<\/b>)(\s*\n*)/g, '$1\n') // Add newline after section headers
      // Clean up but preserve single line breaks
      .replace(/\n\s*\n+/g, '\n') // Replace multiple newlines with single newline
      .replace(/\s+/g, ' ') // Normalize spaces
      .replace(/>\s+</g, '>\n<') // Ensure newline between tags
      .trim()
  );
};

const getThreadId = () => {
  return sessionStorage.getItem(THREAD_KEY);
};

const setThreadId = (threadId) => {
  sessionStorage.setItem(THREAD_KEY, threadId);
};

const clearThreadId = () => {
  sessionStorage.removeItem(THREAD_KEY);
};

/**
 * Encrypts a signature string using AES encryption with a random IV and a SHA256 key.
 * @param {string} signatureString - The signature string to encrypt
 * @returns {string} The encrypted signature string
 */
const encryptSignature = (signatureString) => {
  try {
    // Generate a random Initialization Vector (IV) of 16 bytes
    // IV ensures that encrypting the same text multiple times produces different results
    // This is crucial for security to prevent pattern analysis
    const iv = CryptoJS.lib.WordArray.random(16);

    const key = CryptoJS.SHA256(CryptoJS.enc.Hex.parse(SECRET_KEY));

    // Perform AES encryption in CBC mode
    // - signatureString: the text to encrypt
    // - key: the SHA256 hash of our secret key
    // - iv: random initialization vector
    // - mode: CBC (Cipher Block Chaining) provides better security
    // - padding: PKCS7 ensures the input is properly padded to block size
    const encrypted = CryptoJS.AES.encrypt(signatureString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Combine IV and encrypted data into a single WordArray
    // This is important because the IV is needed for decryption
    // Format: [16 bytes IV][remaining bytes ciphertext]
    const ivAndEncrypted = CryptoJS.lib.WordArray.create()
      .concat(iv) // First 16 bytes will be IV
      .concat(encrypted.ciphertext); // Followed by the encrypted data

    const result = CryptoJS.enc.Base64.stringify(ivAndEncrypted);
    return result;
  } catch (error) {
    // Log any encryption errors and return null to indicate failure
    console.error('Encryption error:', error);
    return null;
  }
};

console.log('Encrypted Signature', encryptSignature('This is a test text'));

/**
 * Gets a signature for API authentication
 * @returns {Promise<string>} Signature for API calls
 */
const getSignature = async () => {
  try {
    const response = await fetch(SIGNATURE_URL);
    if (!response.ok) {
      throw new Error('Failed to get signature');
    }
    const data = await response.json();
    return encryptSignature(data.signature);
  } catch (error) {
    console.error('Error getting signature:', error);
    throw error;
  }
};

/**
 * Makes the initial API call to create a thread and get first response
 * @param {string} message - User's message
 * @returns {Promise<Object>} Response from the assistant
 */
const makeInitialCall = async (message) => {
  try {
    const signature = await getSignature();

    const response = await fetch(`${BASE_URL}/run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
        'x-signature': signature,
      },
      body: JSON.stringify({
        assistant_id: ASSISTANT_ID,
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: message,
          },
        ],
        response_format: {
          type: 'text',
        },
        project_name: 'EXTERNAL_CHATBOT',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get initial response');
    }

    const data = await response.json();

    // Store thread ID for future use
    setThreadId(data.open_ai_run.thread_id);

    // Get the last message from assistant and sanitize it
    const lastMessage = data.messages[data.messages.length - 1];
    const { content, shouldShowCTA, ctaText } = JSON.parse(
      lastMessage.content[0].text.value,
    );
    return {
      text: sanitizeMessage(content),
      shouldShowCTA,
      ctaText,
    };
  } catch (error) {
    console.error('Error in initial API call:', error);
    throw error;
  }
};

/**
 * Makes subsequent API calls using the stored thread ID
 * @param {string} message - User's message
 * @param {string} threadId - Thread ID from previous conversation
 * @returns {Promise<Object>} Response from the assistant
 */
const makeContinuationCall = async (message, threadId) => {
  try {
    const signature = await getSignature();

    const response = await fetch(`${BASE_URL}/${threadId}/run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
        'x-signature': signature,
      },
      body: JSON.stringify({
        assistant_id: ASSISTANT_ID,
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: message,
          },
        ],
        response_format: {
          type: 'text',
        },
        project_name: 'EXTERNAL_CHATBOT',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get continuation response');
    }

    const data = await response.json();

    // Get the last message from assistant and sanitize it
    const lastMessage = data.messages[data.messages.length - 1];
    const { content, shouldShowCTA, ctaText } = JSON.parse(
      lastMessage.content[0].text.value,
    );
    return {
      text: sanitizeMessage(content),
      shouldShowCTA,
      ctaText,
    };
  } catch (error) {
    console.error('Error in continuation API call:', error);
    throw error;
  }
};

/**
 * Process a user query through AI
 * @param {string} query - User's message
 * @returns {Promise<Object>} AI response message object
 */
export const processAIQuery = async (query) => {
  try {
    const threadId = getThreadId();
    let response;

    if (!threadId) {
      // First time query - create new thread
      response = await makeInitialCall(query);
    } else {
      // Continuation of existing conversation
      response = await makeContinuationCall(query, threadId);
    }
    return createBotMessage(response.text, 'text', {
      shouldShowCTA: response.shouldShowCTA,
      ctaText: response.ctaText,
    });
  } catch (error) {
    console.error('Error processing AI query:', error);
    clearThreadId(); // Clear thread ID on error to start fresh next time
    throw error;
  }
};

/**
 * Reset the AI conversation state
 */
export const resetAIConversation = () => {
  clearThreadId();
};

/**
 * Checks if the current flow should use AI
 * @param {Object} currentValidation - Current validation state
 * @param {boolean} isProcessing - Whether a message is being processed
 * @returns {boolean} Whether to use AI flow
 */
export const shouldUseAIFlow = (currentValidation, isProcessing) => {
  return !currentValidation && !isProcessing;
};
