import React from 'react';
import { Avatar } from '@chatscope/chat-ui-kit-react';
import chatLogo from '../../../../images/Chatbot/ChatbotBeaverLogo.png';

export const ChatAISeparator = () => {
  return (
    <div className="my-4 px-4">
      <div className=" rounded-lg">
        <div className="flex flex-col items-center py-4">
          <Avatar src={chatLogo} name="Beavie" size="md" />
          <div className="mt-2 flex flex-col items-center text-xs">
            <div className="flex items-center gap-1">
              <span className="font-bold  text-black">Beavie</span>
              <span className="text-gray-600">joined</span>
            </div>
            <div className="text-sm text-gray-600">
              Powered by <span className="font-medium">AI</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
