import React from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import { ChatOptions } from './ChatOptions';
import chatLogo from '../../../../images/Chatbot/ChatbotBeaverLogo.png';

export const ChatQuestionGroup = ({
  question,
  options,
  onSelect,
  isProcessing,
  isTyping,
  isAIFlowActive,
}) => {
  const handleAIAssistantClick = () => {
    onSelect('ai_assistant');
  };

  return (
    <div className="mt-2 max-w-[80%] rounded-lg bg-[#7A8B871A] p-4">
      <div className="flex flex-col gap-4">
        <Message
          model={{
            message: question.message,
            sentTime: question.sentTime,
            sender: question.sender,
            direction: question.direction,
            position: question.position,
          }}
          className={`text-black ${
            isAIFlowActive ? 'cursor-not-allowed opacity-50' : ''
          }`}
        />
        <ChatOptions
          items={options.payload}
          onSelect={onSelect}
          disabled={isProcessing || isTyping}
          isAIFlowActive={isAIFlowActive}
        />
        <div
          onClick={() => !isAIFlowActive && handleAIAssistantClick()}
          className={`flex cursor-pointer items-center gap-2 rounded-lg bg-[#00937833] p-4 shadow-[0_0_12px_rgba(120,244,200,0.4)] transition duration-200 ease-in-out hover:bg-[#0093784b] ${
            isAIFlowActive || isProcessing || isTyping
              ? 'cursor-not-allowed opacity-50'
              : ''
          }`}
        >
          <img src={chatLogo} alt="AI Assistant" className="h-8 w-8" />
          <span className="font-base text-sm text-black">
            Got specific questions? Talk to our AI Assistant instead!
          </span>
        </div>
      </div>
    </div>
  );
};
