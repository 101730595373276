import React, { useContext } from 'react';
import { Chatbot } from './components/Chatbot';
import { ChatFAB } from './components/ChatFAB';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';

const ExternalChatbot = ({ showTooltip, setHasTrackedOpen }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const isChatbotOpen = state.chatbotOpened;

  //Create a global state to store the chatbot state

  const handleOpen = () => {
    dispatch({
      type: 'SET_CHATBOT_OPENED',
      payload: true,
    });
  };

  const handleClose = () => {
    dispatch({
      type: 'SET_CHATBOT_OPENED',
      payload: false,
    });
    setHasTrackedOpen(false);
  };

  return isChatbotOpen ? (
    <Chatbot onClose={handleClose} />
  ) : (
    <ChatFAB onClick={handleOpen} showTooltip={showTooltip} />
  );
};

export default ExternalChatbot;
